var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from '@shared/ui/navigation/Link';
import RouterLink from '@shared/ui/navigation/RouterLink';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepFormContainer, StepTitle } from '@shared/ui/sidebars/shared';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn } from '@components/styled';
import { BodySmall } from '@components/styled/Typography';
import { getAbsolutePath } from '@app/routing/helpers';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { ReactComponent as SquareIcon } from '@icons/wolfkit-light/square-on-square-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { EXTERNAL_LINKS } from '@shared/ui/sidebars/constants';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import usePortfolioCreate from '../usePortfolioCreate';
import { enterKeyValidationSchema, handleResponseError } from './validation';
const LinkContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.s,
    textAlign: 'center',
}));
const HintText = styled(BodySmall)(props => ({
    fontSize: 14,
    color: props.theme.palette.text.secondary,
}));
const GuideText = styled(BodySmall, { shouldForwardProp: propName => propName !== 'margin' })(props => ({
    fontSize: 14,
    lineHeight: '24px',
    color: props.theme.palette.text.primary,
    margin: props.margin,
}));
const GuideContainer = styled(ContainerColumn)(props => ({
    borderRadius: 12,
    background: '#F8FAFF',
    padding: props.theme.spacing_sizes.xm,
    boxSizing: 'border-box',
    marginBottom: props.theme.spacing_sizes.s * 5,
}));
const ExampleInputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.hasError ?
        props.theme.spacing_sizes.xs * 1.25 : props.theme.spacing_sizes.m * 3,
}));
const GuideLinkContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const EnterKey = ({ handleClose }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const theme = useTheme();
    const { handleExchangeCreate, goToPrevStep, sidebarFormValues, displayBackBtn, isCreatingExchangeAcc, } = usePortfolioCreate({ handleClose });
    const { routes } = useAppNavigation();
    const { control, handleSubmit, formState: { errors }, setError, } = useForm({
        defaultValues: sidebarFormValues,
        resolver: yupResolver(enterKeyValidationSchema(t)),
    });
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield handleExchangeCreate(values);
        if (typeof res !== 'boolean') {
            handleResponseError({
                translateFn: t,
                error: res.error,
                setError,
                exchangeType: 'BINANCE',
            });
        }
    });
    const haveNameErrors = typeof ((_a = errors.exchangeName) === null || _a === void 0 ? void 0 : _a.message) !== 'undefined';
    const haveAPIKeyErrors = typeof ((_b = errors.apiKey) === null || _b === void 0 ? void 0 : _b.message) !== 'undefined';
    const haveSecretKeyErrors = typeof ((_c = errors.secretKey) === null || _c === void 0 ? void 0 : _c.message) !== 'undefined';
    const haveFormErrors = haveAPIKeyErrors || haveNameErrors || haveSecretKeyErrors;
    return (_jsx(SidebarInner, { padding: `
                ${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px 
                ${theme.spacing_sizes.xs * 5}px
            `, title: displayBackBtn ? (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.connect_exchange.back_btn') })) : null, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepFormContainer, { onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepTitle, { margin: `0px 0px ${theme.spacing_sizes.l}px`, children: t('overview.connect_binance_exchange.enter_data.title') }), _jsx(GuideLinkContainer, { children: _jsx(RouterLink, { to: getAbsolutePath(routes.SUPPORT), children: t('overview.connect_binance_exchange.enter_data.full_guide') }) }), _jsxs(GuideContainer, { children: [_jsx(GuideText, { children: Trans({
                                    i18nKey: 'overview.connect_binance_exchange.enter_data.hints.login',
                                    components: [
                                        _jsx(Link, { href: EXTERNAL_LINKS.BINANCE_SETTINGS }, 'api-settings-link'),
                                    ],
                                    values: {
                                        apiSettings: t('overview.connect_binance_exchange.enter_data.hints.login_link_text'),
                                    },
                                }) }), _jsx(GuideText, { margin: `0px 0px ${theme.spacing_sizes.xs * 1.25}px`, children: t('overview.connect_binance_exchange.enter_data.hints.copy_address') }), _jsx(ExampleInputContainer, { children: _jsx(Input, { value: '3.10.145.102,35.3.10.145.102,35.3.10.145.102,35.3.10...', endAdornment: (_jsx(Icon, { IconComponent: SquareIcon, size: 16, color: theme.palette.primary.main })), disabled: true, "aria-disabled": true }) }), _jsx(GuideText, { children: t('overview.connect_binance_exchange.enter_data.hints.paste_address') })] }), _jsx(InputContainer, { hasError: haveNameErrors, children: _jsx(Controller, { name: 'exchangeName', control: control, render: ({ field, fieldState }) => {
                                var _a, _b;
                                return (_jsx(Input, Object.assign({}, field, { label: t('overview.connect_binance_exchange.enter_data.name.label'), maxCount: 50, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isCreatingExchangeAcc, required: true })));
                            } }) }), _jsx(InputContainer, { hasError: haveAPIKeyErrors, children: _jsx(Controller, { name: 'apiKey', control: control, render: ({ field, fieldState }) => {
                                var _a, _b;
                                return (_jsx(Input, Object.assign({}, field, { label: t('overview.connect_binance_exchange.enter_data.api_key.label'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isCreatingExchangeAcc, required: true })));
                            } }) }), _jsx(InputContainer, { hasError: haveSecretKeyErrors, children: _jsx(Controller, { name: 'secretKey', control: control, render: ({ field, fieldState }) => {
                                var _a, _b;
                                return (_jsx(Input, Object.assign({}, field, { label: t('overview.connect_binance_exchange.enter_data.secret_key.label'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: isCreatingExchangeAcc, required: true })));
                            } }) })] })), contentAlign: 'start', buttons: (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'submit', size: 'large', disabled: isCreatingExchangeAcc || haveFormErrors, fullWidth: true, children: t('overview.connect_binance_exchange.enter_data.continue_btn') }), _jsxs(LinkContainer, { children: [_jsx(HintText, { children: t('overview.connect_binance_exchange.enter_data.hint') }), _jsx(Link, { href: EXTERNAL_LINKS.CREATE_BINANCE_ACCOUNT, children: t('overview.connect_binance_exchange.enter_data.hint_link') })] })] })), buttonsGap: theme.spacing_sizes.xm }) }));
};
export default EnterKey;
